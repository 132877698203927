import React, { useEffect, useState } from "react";
import {
  Avatar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Box,
  Typography,
  IconButton,
  useTheme,
  InputLabel,
  TextField,
  Grid,
  MenuItem,
  Button,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Dialog,
} from "@mui/material";
import { CheckCircle, Cancel, Autorenew } from "@mui/icons-material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Colors from "../../../assets/styles";
import UserServices from "../../../api/UserServices/index";
import Loader from "../../../components/Loader";
import { useForm } from "react-hook-form";
import SimpleDialog from "../../../components/Dialog";
import VoucherServices from "../../../api/VoucherServices/index";
import { ErrorHandler } from "../../../utils/ErrorHandler";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { ErrorToaster, SuccessToaster } from "../../../components/Toaster";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import EditIcon from "@mui/icons-material/Edit";
import StarIcon from '@mui/icons-material/Star';

import DeleteIcon from "@mui/icons-material/Delete";

const VoucherList = () => {
  const navigate = useNavigate();
  const [order, setOrder] = useState("asc");

  const [orderBy, setOrderBy] = useState("created_at");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortedRows, setSortedRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectStatus, setSelectStatus] = useState();
  const [selectedUser, setSelectedUser] = useState("");
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [starredRows, setStarredRows] = useState({})

  // State for dialog
  const [open, setOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);

  // Open the confirmation dialog
  const handleDeleteClick = (row) => {
    setRowToDelete(row);
    setOpen(true);
  };

  // Close dialog
  const handleClose = () => {
    setOpen(false);
    setRowToDelete(null);
  };

 

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  console.log(baseUrl);

  const tableHead = [
    { name: "name", label: "Name" },
    { name: "email", label: "Email" },
    { name: "available_credit", label: "Available Credit" },
    { name: "Premium", label: "Premium User" },
    { name: "created_at", label: "Created At" },
    { name: "action", label: "Action" },
  ];

  useEffect(() => {
    getUsers(page  ,rowsPerPage);
  }, []);

  useEffect(() => {
    handleSort(orderBy, order);
  }, [users]);

  const getUsers = async (newPage , rowsPerPage) => {
    setLoading(true);
    try {
      const { data, responseCode, message } =await UserServices.getUsers(newPage == 0 ? 1 :newPage,rowsPerPage);
      console.log(data?.customers);
      setUsers(data?.customers);
      setCount(data?.count)
      setLoading(false);
    } catch (error) {
      console.error("Error while fetching users:", error);
    }
  };
  const updateUser = async (row) => {
    console.log(row)
    const obj = {
      is_premium :row?.is_premium ? false :true ,
      user_id : row?._id
    }
    setLoading(true);
    try {
      const { data, responseCode, message } =await UserServices.updateUser(obj);
      setLoading(false);
      getUsers(page  ,rowsPerPage)
    } catch (error) {
      console.error("Error while fetching users:", error);
    }
  };

  
 
  const handleSort = (column, direction) => {
    const isAsc = direction === "desc";
    const sorted = [...users].sort((a, b) => {
      let aValue = a[column];
      let bValue = b[column];

      // Handle nested properties
      if (column === "customer") {
        aValue = `${a.customer.first_name} ${a.customer.last_name}`;
        bValue = `${b.customer.first_name} ${b.customer.last_name}`;
      } else if (column === "details") {
        aValue = a.details
          .map((detail) => `${detail.size} ${detail.qty} ${detail.design}`)
          .join(", ");
        bValue = b.details
          .map((detail) => `${detail.size} ${detail.qty} ${detail.design}`)
          .join(", ");
      }

      if (aValue < bValue) {
        return isAsc ? -1 : 1;
      }
      if (aValue > bValue) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    console.log(direction);
    setSortedRows(sorted);
    setOrder(direction);
    setOrderBy(column);
  };

  const handleSortRequest = (column) => {
    const isAsc = orderBy === column && order === "asc";
    handleSort(column, isAsc ? "desc" : "asc");
  };

  const handleChangePage = (event, newPage) => {
    const updatedPage = newPage 
    setPage( updatedPage);
    getUsers(updatedPage ,rowsPerPage)
  };
  const handleChangePage1 = (event, newPage) => {
    const updatedPage = newPage  + 1
    setPage( updatedPage);
    getUsers(updatedPage ,rowsPerPage)
  };

 
  useEffect(() => {
    if (selectedUser) {
      console.log(selectedUser);
      setValue("status", selectedUser.status);
      setSelectStatus(selectedUser.status);
    }
  }, [openDialog, selectedUser, setValue]);



  return (
    <>
      <Paper
        sx={{
          boxShadow: "rgba(0, 0, 0, 0.02) 0px 3.5px 5.5px",
          borderRadius: "15px",
        }}
      >
        <TableContainer sx={{ mt: 12 }}>
          <Box sx={{ m: 2, display: "flex", justifyContent: "end" }}>
       
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                {tableHead.map((cell) => (
                  <TableCell key={cell.name}>
                    <TableSortLabel
                      active={orderBy === cell.name}
                      direction={orderBy === cell.name ? order : "asc"}
                      onClick={() => handleSortRequest(cell.name)}
                      sx={{
                        svg: {
                          fontSize: "12px",
                          color: `${Colors.grey} !important`,
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color: Colors.grey,
                          fontSize: "12px",
                          fontWeight: 700,
                          textTransform: "uppercase",
                        }}
                      >
                        {cell.label}
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={tableHead?.length + 1}>
                    <Loader width="40px" height="40px" />
                  </TableCell>
                </TableRow>
              ) : (
                sortedRows.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell>{row?.name}</TableCell>
                      <TableCell>{row?.email}</TableCell>
                      <TableCell>{row?.available_credit}</TableCell>
                      <TableCell>
                        <Box onClick={()=>updateUser(row)} sx={{cursor:"pointer"}}>

                     { row?.is_premium  ? 
                      <StarIcon /> : <StarBorderIcon /> }
                        </Box>
               
            </TableCell>
                      <TableCell> {moment(row?.created_at).format("DD-MM-YYYY")} </TableCell>
                      <TableCell onClick={()=>navigate("/view-design",{state:row?._id})}> < MoreVertIcon sx={{cursor:"pointer"}} />  </TableCell>
                 
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>

          {/* Confirmation Dialog */}
        
        </TableContainer>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <Typography>
            Showing {Math.min((page ) * rowsPerPage, count)} of{" "}
            {count} entries
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            {page != 1 && (
              <IconButton
                sx={{ border: "1px solid grey", p: 1 }}
                onClick={(e) => handleChangePage(e, page  - 1)}
                disabled={page === 0}
              >
                <KeyboardArrowLeft />
              </IconButton>
            )}
            <Box sx={{ display: "flex", gap: "5px" }}>
              {[
                ...Array(Math.ceil(count / rowsPerPage)).keys(),
              ].map((pageNum) => (
                <Box
                  style={{
                    border: "1px solid grey",
                    p: 1,
                    borderRadius: "50%",
                    minWidth: "40px",
                    minHeight: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    backgroundColor: page === pageNum +1 ? Colors.primary : "",
                    color: page === pageNum +1? Colors.white : Colors.black,
                  }}
                  key={pageNum}
                  onClick={(e) => handleChangePage1(e, pageNum)}
                >
                  {pageNum + 1}
                </Box>
              ))}
            </Box>
            {page !== Math.ceil(count / rowsPerPage) + 1 && (
              <IconButton
                sx={{ border: "1px solid grey", p: 1 }}
                onClick={(e) => handleChangePage(e, page + 1)}
                disabled={
                  page == Math.ceil(count / rowsPerPage) 
                }
              >
                <KeyboardArrowRight />
              </IconButton>
            )}
          </Box>
        </Box>
      </Paper>
      
    </>
  );
};

export default VoucherList;
