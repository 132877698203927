import { Avatar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Paper, Step, StepConnector, StepLabel, Stepper, Typography, styled } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import moment from 'moment';

import CircleIcon from "@mui/icons-material/Circle";
import { deepPurple } from '@mui/material/colors';
import Images from '../../../../assets/images/index'

import { PDFExport } from '@progress/kendo-react-pdf';
import OrderServices from '../../../../api/orderServices/order.index';
import { domToPng } from 'modern-screenshot';
import axios from 'axios';
import UserServices from '../../../../api/UserServices';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1.5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const useStyles = styled({
    blurredBackground: {
        filter: 'blur(12px)', // Apply the blur effect
        transition: 'filter 0.3s ease', // Smooth transition
    },
    popupImage: {
        width: '100%',
        maxWidth: '300px',
        margin: '0 auto',
    },
    popupButton: {
        backgroundColor: '#00a8ff', // Same button color
        color: '#fff',
        marginTop: '20px',
    },
});

const steps = ['Pending', 'Processing', 'Confirmed', 'Delivered'];


const UserDesign = () => {

    const classes = useStyles();
    const { state } = useLocation()
    console.log(state, 'state');
    const navigate = useNavigate()
    const [rotateDirection, setRotateDirection] = useState(false)
    console.log(state);
    const [tshirtPrice, setTshirtPrice] = useState(0)
    console.log(state);
    const [open, setOpen] = useState(false);
    const [allData, setAllData] = useState(null)
    const [designData, setDesignData] = useState(null)
    const pdfExportComponentFront = useRef(null);
    const pdfExportComponentBack = useRef(null);
    const elementFront = useRef(null)
    const elementBack = useRef(null)
    const parentRef = useRef(null);
    const divRef = useRef(null);
    const divRefBack = useRef(null);
    const [checked, setChecked] = React.useState(false);
    const [loader, setLoader] = useState(false)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getUserDesignData = async () => {
        try {
            let params = {

            }
            const { data } = await UserServices.getDesign(state)
            console.log(data);
            setDesignData(data.designs)


        } catch (error) {
            console.log(error);
        } finally {

        }
    }
    const [status, setStatus] = useState(1)
    useEffect(() => {

        getUserDesignData()
    }, [])

    const Base64Update = async (base64, name) => {
        let obj = {
            document: base64.split(',')[1],
            filename: name

        }

        try {
            const response = await axios.post('https://prod.printmeup.ai/api/system/uploadDesign', obj);

            console.log('Upload successful:', response.data.data.path);
            return (response.data.data.path)

        } catch (error) {
            console.error('Error uploading the file:', error);
        }
    };
    const capture = async () => {
        setLoader(true)
        try {
            const div = divRef.current;
            if (!div) {

                return;
            }

            await waitForImagesToLoad(div); // Ensure images are loaded

            const a4Width = 220; // mm
            const a4Height = 317; // mm
            const scale = 10; // Adjust for image resolution

            // Set div styles to match A4 size
            div.style.width = `${a4Width}mm`;
            div.style.height = `${a4Height}mm`;
            div.style.padding = '0';
            div.style.border = 'none';
            div.style.overflow = 'hidden';


            // Capture the screenshot
            const dataUrl = await domToPng(div, {
                bgcolor: 'transparent',
                width: a4Width * scale,
                height: a4Height * scale,
                useCORS: true,
                style: {
                    transform: `scale(${scale})`,
                    transformOrigin: 'top left',
                },
            });

            const url = await Base64Update(dataUrl, 'front.png'); // Await Base64Update
            window.location.href = url; // Redirect to the updated URL

        } catch (error) {

            setLoader(false)
        }
        finally {
            setLoader(false)
        }
    };


    const captureBack = async () => {
        setLoader(true)
        try {
            const div = divRefBack.current;
            if (!div) {

                return;
            }

            await waitForImagesToLoad(div); // Ensure images are loaded

            const a4Width = 220; // mm
            const a4Height = 317; // mm
            const scale = 10; // Adjust for image resolution

            // Set div styles to match A4 size
            div.style.width = `${a4Width}mm`;
            div.style.height = `${a4Height}mm`;
            div.style.padding = '0';
            div.style.border = 'none';
            div.style.overflow = 'hidden';



            // Capture the screenshot
            const dataUrl = await domToPng(div, {
                bgcolor: 'transparent',
                width: a4Width * scale,
                height: a4Height * scale,
                useCORS: true,
                style: {
                    transform: `scale(${scale})`,
                    transformOrigin: 'top left',
                },
            });

            const url = await Base64Update(dataUrl, 'back.png'); // Await Base64Update
            window.location.href = url; // Redirect to the updated URL

        } catch (error) {
            setLoader(false)
        }
        finally {
            setLoader(false)
        }
    };


    const waitForImagesToLoad = () => {
        return new Promise((resolve) => {
            const imgElements = document.querySelectorAll('img'); // Update this selector as per your needs

            let loadedCount = 0;

            imgElements.forEach((img) => {
                if (img.complete) {
                    loadedCount++;
                } else {
                    img.onload = () => {
                        loadedCount++;
                        if (loadedCount === imgElements.length) {
                            resolve();
                        }
                    };
                }
            });

            if (loadedCount === imgElements.length) {

                resolve();
            }
        });
    };

    return (
        <div >
            <Dialog slotProps={{
                backdrop: {
                    sx: {
                        backdropFilter: "blur(8px)",
                        backgroundColor: "transparent",
                    }

                },


            }}
                PaperProps={{
                    sx: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        borderRadius: '12px'
                    }
                }} maxWidth={'lg'} sx={{ borderRadius: '12px' }} open={open} onClose={handleClose}>

                <DialogContent className={classes.blurredBackground} sx={{ backgroundColor: '#0F172A', overflow: 'hidden' }}>
                    <Grid container justifyContent={'space-around'}>

                        <Button onClick={() => {
                            if (!loader) {
                                capture()
                            }
                        }} sx={{ textTransform: 'capitalize', border: '1px solid #0EA5EA', width: '180px', height: "40px" }}>{loader ? <CircularProgress size={15} /> : 'Download Front Design'}</Button>
                        <Button onClick={() => {
                            if (!loader) {
                                captureBack()
                            }
                        }} sx={{ textTransform: 'capitalize', border: '1px solid #0EA5EA', width: '180px', height: "40px" }}>{loader ? <CircularProgress size={15} /> : 'Download Back Design'}</Button>
                    </Grid>
                    <Grid container >

                        {<Grid component={'div'} className={`  tshirt-img-scale2`} ref={elementFront} item lg={6} md={12} sx={{ display: 'flex', justifyContent: 'center', height: '570px !important', position: 'relative' }}>
                            <div style={{ position: 'relative' }}>

                                <img width={'500px'} height={'570px'} id="tshirt-backgroundpicture" src={Images[allData?.color ? allData?.color : 'white']} />
                                <div style={{ position: 'absolute', top: 90, left: 145, backgroundColor: 'rgba(0,0,0,0)' }}>
                                    {!rotateDirection && <PDFExport ref={pdfExportComponentFront} paperSize="A4" scale={2.7} fileName="Frontprint">
                                        <div ref={divRef} style={{ backgroundColor: 'transparent' }} id='pdfFront'>
                                            <div className=' pdf-data' ref={parentRef} style={{ width: '220px', height: '313px', position: 'relative', overflow: 'hidden' }}>

                                                {allData?.frontText && allData.frontText.length > 0 && allData.frontText.map((item, index) => {
                                                    console.log(item?.fontSize, 'dedede');
                                                    const textStyle = {
                                                        fontSize: item?.fontSize?.toString().includes('px') ? item.fontSize : `${item.fontSize}px`,
                                                        fontWeight: item.fontWeight,
                                                        fill: item.color,
                                                        fontFamily: item.fontFamily,

                                                    };

                                                    const circlePath = `M ${item.width / 2}, ${item.width / 2} m -${item.width / 3}, 0 a ${item.width / 3},${item.width / 3} 0 1,1 ${2 * (item.width / 3)},0 a ${item.width / 3},${item.width / 3} 0 1,1 -${2 * (item.width / 3)},0`;
                                                    const ellipsePath = `M ${item.width / 2}, ${item.width / 2} m -${item.width / 3}, 0 a ${item.width / 3},${item.width / 6} 0 1,1 ${2 * (item.width / 3)},0 a ${item.width / 3},${item.width / 6} 0 1,1 -${2 * (item.width / 3)},0`;
                                                    const wavePath = `M 10, ${item.width / 2} Q ${item.width / 5}, ${item.width / 5} ${2 * (item.width / 5)}, ${item.width / 2} T ${4 * (item.width / 5)}, ${item.width / 2} T ${item.width - 10}, ${item.width / 2}`;
                                                    const straightPath = `M 0,${item.width / 2} L ${item.width},${item.width / 2}`;

                                                    const shapePaths = {
                                                        circlePath,
                                                        ellipsePath,
                                                        wavePath,
                                                        straightPath,
                                                    };

                                                    const selectedPath = shapePaths[item.shape] || '';
                                                    const uniqueId = `front-circlePath${index}`;

                                                    return (
                                                        <React.Fragment key={index}>
                                                            <div
                                                                id={index}
                                                                style={{
                                                                    position: 'absolute',
                                                                    width: `${item.width}px`,
                                                                    height: `${item.height}px`,
                                                                    top: item?.top?.toString().includes('px') ? item.top : `${item.top}px`,
                                                                    left: item?.left?.toString().includes('px') ? item.left : `${item.left}px`,
                                                                    transform: `rotate(${item.rotation}deg)`,
                                                                    zIndex: item.zIndexes,
                                                                    backgroundImage: `url('${item.url}')`,
                                                                    backgroundSize: 'contain',
                                                                    backgroundRepeat: 'no-repeat',
                                                                    backgroundPosition: 'center'
                                                                }}
                                                            >


                                                            </div>
                                                        </React.Fragment>
                                                    );
                                                })}


                                                {allData?.frontPic.length > 0 && allData?.frontPic.map((imageData, index) => {
                                                    console.log(imageData)
                                                    return (
                                                        <div key={index}>
                                                            <Grid
                                                                component={'div'}
                                                                className={`Design-${index}`}
                                                                style={{
                                                                    clipPath: imageData.clipPath,
                                                                    width: `${imageData.width}px`,
                                                                    height: `${imageData.height}px`,
                                                                    position: 'absolute',
                                                                    backgroundImage: `url(${imageData.imageUrl}), url(https://corsproxy.io/?url=${imageData.imageUrl})`,
                                                                    backgroundSize: 'contain',
                                                                    backgroundPosition: 'center',
                                                                    backgroundRepeat: 'no-repeat',
                                                                    top: `${imageData.top}px`,
                                                                    left: `${imageData.left}px`,
                                                                    borderRadius: `${imageData.borderRadius}px`,
                                                                    transform: `${imageData.rotation}`,
                                                                    zIndex: imageData?.zIndexes,
                                                                }}
                                                                onError={(e) => {
                                                                    // Fallback to direct image URL if the proxy fails
                                                                    e.target.style.backgroundImage = `url(${imageData.imageUrl})`;
                                                                }}
                                                            />

                                                            {/* Add overlay image */}
                                                            {checked && <Grid
                                                                component={'div'}
                                                                className={`Overlay-${index}`}
                                                                style={{
                                                                    width: `${imageData.width}px`,
                                                                    height: `${imageData.height}px`,
                                                                    position: 'absolute',
                                                                    top: `${imageData.top}px`,
                                                                    left: `${imageData.left}px`,
                                                                    backgroundImage: `url(${allData?.color == 'white' ? Images?.toneWhite : allData?.color == 'black' ? Images?.toneBlack : allData?.color == 'lightgreen' ? Images?.toneGreen : allData?.color == 'darkgreen' ? Images?.toneDarkGreen : allData?.color == 'blue' ? Images?.toneBlue : allData?.color == 'yellow' ? Images?.toneOrange : allData?.color == 'purple' ? Images?.tonePurple : 'white'})`, // Path to the overlay image
                                                                    backgroundSize: 'contain',
                                                                    backgroundPosition: 'center',
                                                                    backgroundRepeat: 'no-repeat',
                                                                    opacity: 0.5, // Adjust the transparency of the overlay image
                                                                    zIndex: imageData?.zIndexes + 1 // Place overlay above the image
                                                                }}
                                                            />}
                                                        </div>
                                                    );
                                                })}


                                            </div>
                                        </div>
                                    </PDFExport>}

                                </div>
                            </div></Grid>}

                        {<Grid item lg={6} md={12} className={`  tshirt-img-scale2`} ref={elementBack} sx={{ display: 'flex', justifyContent: 'center', height: '570px !important' }}> <div style={{ position: 'relative' }}>
                            <img width={'500px'} height={'570px'} id="tshirt-backgroundpicture" src={Images[allData?.color ? allData?.color + "Back" : 'white' + "Back"]} />
                            <div style={{ position: 'absolute', top: 90, left: 145 }}>

                                <PDFExport ref={pdfExportComponentBack} paperSize="A4" scale={2.7} fileName="Backprint">
                                    <div ref={divRefBack} style={{ backgroundColor: 'transparent' }} id='pdfBack'>
                                        <div className='pdf-data' ref={parentRef} style={{ width: '220px', height: '313px', position: 'relative', overflow: 'hidden' }}>

                                            {allData?.backText && allData?.backText.length > 0 && allData.backText.map((item, index) => {
                                                const textStyle = {

                                                    fontSize: item?.fontSize?.toString().includes('px') ? item.fontSize : `${item.fontSize}px`,
                                                    fontWeight: item.fontWeight,
                                                    fill: item.color,
                                                    fontFamily: item.fontFamily,

                                                };
                                                const circlePath = `M ${item.width / 2}, ${item.width / 2} m -${item.width / 3}, 0 a ${item.width / 3},${item.width / 3} 0 1,1 ${2 * (item.width / 3)},0 a ${item.width / 3},${item.width / 3} 0 1,1 -${2 * (item.width / 3)},0`;
                                                const ellipsePath = `M ${item.width / 2}, ${item.width / 2} m -${item.width / 3}, 0 a ${item.width / 3},${item.width / 6} 0 1,1 ${2 * (item.width / 3)},0 a ${item.width / 3},${item.width / 6} 0 1,1 -${item.width / 3},0`;
                                                const wavePath = `M 10, ${item.width / 2} Q ${item.width / 5}, ${item.width / 5} ${2 * (item.width / 5)}, ${item.width / 2} T ${4 * (item.width / 5)}, ${item.width / 2} T ${item.width - 10}, ${item.width / 2}`;
                                                const straightPath = `M 0,${item.width / 2} L ${item.width},${item.width / 2}`;

                                                const shapePaths = {
                                                    circlePath,
                                                    ellipsePath,
                                                    wavePath,
                                                    straightPath,
                                                };

                                                const selectedPath = shapePaths[item.shape] || '';
                                                const uniqueId = `back-circlePath${index}`;

                                                return (
                                                    <React.Fragment key={index}>

                                                        <div
                                                            id={`back${index}`}
                                                            style={{
                                                                position: 'absolute',
                                                                width: `${item.width}px`,
                                                                height: `${item.height}px`,
                                                                top: item?.top?.toString().includes('px') ? item.top : `${item.top}px`,
                                                                left: item?.left?.toString().includes('px') ? item.left : `${item.left}px`,
                                                                transform: `rotate(${item.rotation}deg)`,
                                                                zIndex: item.zIndexes,
                                                                backgroundImage: `url('${item.url}')`,
                                                                backgroundSize: 'contain',
                                                                backgroundRepeat: 'no-repeat',
                                                                backgroundPosition: 'center'
                                                            }}>

                                                        </div>

                                                    </React.Fragment>
                                                );
                                            })}

                                            {allData?.backPic.length > 0 && allData?.backPic.map((imageUrl, index) => (
                                                <div key={index}>
                                                    <Grid
                                                        component={'div'}
                                                        className={`Design2-${index}`}
                                                        style={{
                                                            clipPath: allData?.backPic[index].clipPath,
                                                            width: `${allData?.backPic[index].width}px`,
                                                            height: `${allData?.backPic[index].height}px`,
                                                            position: 'absolute',
                                                            backgroundImage: `url(https://corsproxy.io/?url=${imageUrl?.imageUrl}), url(${imageUrl?.imageUrl})`,
                                                            backgroundSize: 'contain',
                                                            backgroundPosition: 'center',
                                                            backgroundRepeat: 'no-repeat',
                                                            top: `${allData?.backPic[index].top}px`,
                                                            left: `${allData?.backPic[index].left}px`,
                                                            borderRadius: `${allData?.backPic[index].borderRadius}px`,
                                                            transform: `${allData?.backPic[index].rotation}`,
                                                            zIndex: imageUrl?.zIndexes,
                                                        }}
                                                        onError={(e) => {
                                                            // Handle image fallback if the proxy fails
                                                            e.target.style.backgroundImage = `url(${imageUrl?.imageUrl})`;
                                                        }}
                                                    />

                                                    {false && <Grid
                                                        component={'div'}
                                                        className={`Overlay2-${index}`}
                                                        style={{
                                                            width: `${imageUrl.width}px`,
                                                            height: `${imageUrl.height}px`,
                                                            position: 'absolute',
                                                            top: `${imageUrl.top}px`,
                                                            left: `${imageUrl.left}px`,
                                                            backgroundImage: `url(${allData?.color == 'white' ? Images?.toneWhite : allData?.color == 'black' ? Images?.toneBlack : allData?.color == 'lightgreen' ? Images?.toneGreen : allData?.color == 'darkgreen' ? Images?.toneDarkGreen : allData?.color == 'blue' ? Images?.toneBlue : allData?.color == 'yellow' ? Images?.toneOrange : allData?.color == 'purple' ? Images?.tonePurple : 'white'})`, // Path to the overlay image
                                                            backgroundSize: 'contain',
                                                            backgroundPosition: 'center',
                                                            backgroundRepeat: 'no-repeat',
                                                            opacity: 0.5, // Adjust the transparency of the overlay image
                                                            zIndex: imageUrl?.zIndexes + 1 // Place overlay above the image
                                                        }}
                                                    />}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </PDFExport>
                            </div>
                        </div></Grid>}
                    </Grid>
                </DialogContent>

            </Dialog>

            <Box sx={{ width: '100%', margin: '0 auto', borderRadius: '12px', mt: "80px" }} >

                <Grid container gap={2}>

                    <Grid container mt={4}>
                        <Box component={'div'} className='heading-font' sx={{ fontWeight: 700, fontSize: '24px' }}>Designs</Box>
                    </Grid>
                    <Grid container gap={2}>
                        {designData?.length > 0 ? (
                            designData?.map((item, index) => (

                                <Grid
                                    item
                                    lg={3.5}
                                    md={5}
                                    sm={12}
                                    xs={12}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    gap={2}
                                    p={2}
                                    sx={{
                                        backgroundColor: 'white',
                                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                                        borderRadius: '12px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: 1,
                                            position: 'relative',
                                            '&:hover .overlay': {
                                                opacity: 1,
                                            },
                                        }}
                                    >
                                        {/* Image Container */}
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                width: '200px',
                                                height: '200px',
                                                background: "#0BD1D14D"
                                            }}
                                        >
                                            <Box
                                                component="img"
                                                src={item.generated_art}
                                                alt="Item"
                                                sx={{
                                                    width: '100%',
                                                    height: '100%',
                                                    borderRadius: 1,
                                                }}
                                            />
                                            {/* Overlay */}
                                            <Box
                                                className="overlay"
                                                component={'div'}
                                                sx={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    opacity: 0,
                                                    transition: 'opacity 0.3s ease',
                                                    borderRadius: 1,
                                                    color: 'white',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    setOpen(true);
                                                    setAllData(item?.design);
                                                }}
                                            >
                                                <Button sx={{ color: 'white', textTransform: 'none' }}>Preview</Button>
                                            </Box>
                                        </Box>

                                        {/* Text Below Image */}
                                        <Box sx={{ textAlign: 'center' }}>
                                            <Typography variant="body2" sx={{ color: 'black', mt: 1 }}>
                                                Name: <span style={{ color: 'black' }}>{item.name || 'N/A'}</span>
                                            </Typography>
                                            <Typography variant="body2" sx={{ color: 'black', mt: 1 }}>
                                                Created At: <span style={{ color: 'black' }}>{moment(item.created_at).format("DD-MM-YYYY") || 'N/A'}</span>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>


                            ))
                        ) : (
                            <Typography sx={{ color: '#0BD1D1', textAlign: 'center' }}>
                                No Design Found.
                            </Typography>
                        )}

                    </Grid>
                </Grid>

            </Box>
        </div>
    )
}

export default UserDesign
