import Dashboard from "../../views/admin/dashboard";
import UsersList from "../../views/admin/users/index";
import OrderList from "../../views/admin/orders/index" ;
import Pages from "../../views/admin/pages";
import Login from "../../views/admin/Login/index";
import Settings from "../../views/admin/settings";
import OrderDetail from "../../views/admin/orderdetails";
import CollectionsCategory from "../../views/admin/colletionsCat";
import CollectionsCatList from "../../views/admin/CollectionsCatList";
import CollectionCatUpdate from "../../views/admin/collectionsCatUpdate";
import CreateCollection from "../../views/admin/CreateCollection";
import CollectionsList from "../../views/admin/CollectionList";
import UpdateCollection from "../../views/admin/UpdateCollection";
import VoucherList from "../../views/admin/Vouchers/voucherlist";
import CreateVoucher from "../../views/admin/Vouchers/createvoucher";
import UpdateVoucher from "../../views/admin/Vouchers/updatevoucher";
import Colors from "../../views/admin/Colors";
import Costings from "../../views/admin/Costings";
import UserDesign from "../../views/admin/users/User Design";


const AdminRoutes = [
  {
    path: "/login",
    component: <Login />
  },
  {
    path: "/dashboard",
    component: <Dashboard />
  },
  {
    path: "/crm",
    component: <Dashboard />
  },
  {
    path: "/pages",
    component: <Pages />
  },
  
  {
    path: "/collections-category",
    component: <CollectionsCategory />
  },
  {
    path: "/collection-list",
    component: <CollectionsList />
  },
  {
    path: "/update-collection",
    component: <UpdateCollection />
  },
  {
    path: "/collections-category-update",
    component: <CollectionCatUpdate />
  },
 
  {
    path: "/collections-category-list",
    component: <CollectionsCatList />
  },
  {
    path: "/create-collection",
    component: <CreateCollection />
  },
  {
    path: "/userslist",
    component: <UsersList />
  },
  {
    path: "/orderslist",
    component: <OrderList />
  },
  {
    path: "/voucher-list",
    component: <VoucherList />
  },
  {
    path: "/create-voucher",
    component: <CreateVoucher />
  },
  {
    path: "/update-voucher",
    component: <UpdateVoucher />
  },
  {
    path: "/colors",
    component: <Colors />
  },
  {
    path: "/costing",
    component: <Costings />
  },
  {
    path: "/orderDetail",
    component: <OrderDetail />
  },
  {
    path: "/settings",
    component: <Settings />
  },
  {
    path: "/view-design",
    component: <UserDesign />
  },
];

export default AdminRoutes;